<template>
  <div class="page-farm-yiled">
    <div class="card card-custom gutter-b dashboard-farm-controller">
      <!--begin::Header-->
      <div class="card-header border-0">
        <t-farmon-table-header
            :total-count="tableState==0 ? paging.totalCount: yieldPaging.totalCount"
        ></t-farmon-table-header>
        <div class="card-toolbar toolbar-symbol">
          <b-button v-show='tableState == 0' variant="dark" class="rounded-sm float-right" size="sm" @click='showYieldPlanModal(null)'>{{i18n.addPlan}}</b-button>
        </div>
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body pt-0">
        <div class="as-tabs">
        <b-tabs>
          <b-tab v-for="(item, i) in yieldContent" :key="i" :active="i == tableState" @click="changeConnect(i)">
            <template slot="title">
              <span class="nav-text font-weight-bold font-size-h6 font-size-h4-lg">{{ item }}</span>
            </template>
          </b-tab>
          <!--begin::yielPlanInfo-table-->
          <div v-show="tableState == 0" class="yielPlanInfo-table mt-4">
            <div class="row mb-2">
            <div class="form-inline col-xxl-2 col-lg-4 mb-3">
              <label class="text font-size-h6 font-weight-bold mr-5"> {{i18n.cropName}} </label>
                <b-form-select v-model="selectCropNo" :options="selectCropNmList" style="width: 145px" @change="[paging.pageNo=1, linkGen(), getYieldPlans()]"></b-form-select>
            </div>
            <div class="form-inline col-xxl-2 col-lg-4 mb-3">
              <label class="text font-size-h6 font-weight-bold mr-5"> {{i18n.startDate}} </label>
              <b-form-datepicker
                  v-model="startYmd"
                  :locale="locale"
                  class="farm-custom-datepicker"
                  placeholder=""
                  style="width: 145px"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  @input="[paging.pageNo=1, linkGen(), getYieldPlans()]"
              ></b-form-datepicker>
            </div>
            <div class="form-inline col-xxl-2 col-lg-4 mb-3">
              <label class="text font-size-h6 font-weight-bold mr-5"> {{i18n.endDate}} </label>
              <b-form-datepicker
                  v-model="endYmd"
                  :locale="locale"
                  class="farm-custom-datepicker"
                  placeholder=""
                  style="width: 145px"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  @input="[paging.pageNo=1, linkGen(), getYieldPlans()]"
              ></b-form-datepicker>
            </div>
            <div class="form-inline col-xxl-3 col-lg-4 mb-3">
              <span class="text font-weight-bold text-primary">{{i18n.asOfPlantDate}} : {{`${selectCropNmList.filter(crop => crop.value == this.selectCropNo)[0].text} ${startYmd == null ? '':startYmd} ~ ${endYmd == null ? '':endYmd}`}}</span>
            </div>
            <div class="d-flex align-items-start flex-wrap">
              <b-list-group horizontal class="mb-5" style="margin-right: auto;">
                <b-list-group-item
                  v-for="(dateRange) in fixedDateRange.dateRanges"
                  button
                  :key="dateRange.key"
                  :active="fixedDateRange.activeDateRange === dateRange.key"
                  @click="[onFixedDateRangeChanged(dateRange.key, false), paging.pageNo=1, linkGen(), getYieldPlans()]"
                >
                  {{ dateRange.date }}
                </b-list-group-item>
              </b-list-group>
            </div>
           </div>
            <v-data-table
                :headers="yieldPlanFields"
                :items="yieldPlanItems"
                item-key="farmYieldPlanNo"
                class="elevation-1 farm-data-table"
                :no-data-text="`${i18n.noData}`"
                hide-default-footer
                show-expand
                single-expand
            >

              <template v-slot:[`expanded-item`]="{headers, item}">
                <td :colspan="headers.length" class="bg-white">
                  <v-data-table
                    :headers="yieldFields"
                    :items="yieldItemsExpand(item, headers)"
                    class="elevation-1 bg-secondary"
                    :no-data-text="`${i18n.noData}`"
                    hide-default-footer
                    item-key="farmYieldNo"
                    dense
                  >
                      <template v-slot:[`item.fieldH`]="{ item }">
                      <b-button size="sm" variant="outline-dark" @click="showYieldAddModal(item)">
                          <span class='icon-xs fas fa-pen'></span>
                      </b-button>
                      <b-button size="sm" variant="dark" class="rounded-sm" @click="deleteYieldModal(item)">
                          <span class="fas fa-trash font-size-sm"></span>
                      </b-button>
                      </template>
                  </v-data-table>
                </td>
              </template>

              <template v-slot:[`item.fieldM`]="{ item }">
                <b-button size="sm" variant="outline-primary" @click="showYieldAddModal(item)">
                  <span class='icon-xs fas fa-seedling mr-2'></span>
                  <span class="font-weight-bold ">{{i18n.yieldRegistration}}</span>
                </b-button>
              </template>
              <template v-slot:[`item.fieldL`]="{ item }">
                <b-button size="sm" variant="outline-dark" @click="showYieldPlanModal(item)">
                  <span class='icon-xs fas fa-pen mr-2'></span>
                  <span class="font-weight-bold">{{i18n.edit}}</span>
                </b-button>
                <b-button size="sm" variant="dark" class="rounded-sm" @click="delYieldPlanModal(item)">
                  <span class="fas fa-trash font-size-sm mr-2"></span>
                  <span class="text font-weight-normal">{{i18n.delete}}</span>
                </b-button>
              </template>


            </v-data-table>
            <!--begin::pagination-->
            <div v-if="totalPage" class="pagination mt-12">
              <b-pagination-nav
                :number-of-pages="totalPage"
                :link-gen="linkGen"
                use-router
                limit="10"
              >
                <template #first-text><i class="fas fa-angle-double-left"></i></template>
                <template #prev-text><i class="fas fa-angle-left"></i></template>
                <template #next-text><i class="fas fa-angle-right"></i></template>
                <template #last-text><i class="fas fa-angle-double-right"></i></template>
                <template #page="{ page, active }">
                  <span v-if="active" class="font-size-sm font-weight-bold text-white">{{ page }}</span>
                  <span v-else class="font-size-sm font-weight-bold text-dark">{{ page }}</span>
                </template>
              </b-pagination-nav>
            </div>
            <!--end::pagination-->
          </div>
          <!--end::yielPlanInfo-table-->

          <!--begin::yieldInfo-table-->
          <div v-show="tableState == 1" class="yieldInfo-table mt-4">
            <div class="row mb-2">
            <div class="form-inline col-xxl-2 col-lg-4 mb-3">
              <label class="text font-size-h6 font-weight-bold mr-5"> {{i18n.cropName}} </label>
                <b-form-select v-model="selectYieldCropNo" :options="selectYieldCropNmList" style="width: 145px" @change='[yieldPaging.pageNo=1, yieldlinkGen(),getYieldTotalCrops()]'></b-form-select>
            </div>
            <div class="form-inline col-xxl-2 col-lg-4 mb-3">
              <label class="text font-size-h6 font-weight-bold mr-5"> {{i18n.startDate}} </label>
              <b-form-datepicker
                  v-model="yieldStartYmd"
                  :locale="locale"
                  class="farm-custom-datepicker"
                  placeholder=""
                  style="width: 145px"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  @input='[yieldPaging.pageNo=1, yieldlinkGen(),getYieldTotalCrops()]'
              ></b-form-datepicker>
            </div>
            <div class="form-inline col-xxl-2 col-lg-4 mb-3">
              <label class="text font-size-h6 font-weight-bold mr-5"> {{i18n.endDate}} </label>
              <b-form-datepicker
                  v-model="yieldEndYmd"
                  :locale="locale"
                  class="farm-custom-datepicker"
                  placeholder=""
                  style="width: 145px"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  @input='[yieldPaging.pageNo=1, yieldlinkGen(),getYieldTotalCrops()]'
              ></b-form-datepicker>
            </div>
            <div class="form-inline col-xxl-3 col-lg-4 mb-3">
              <span class="text font-weight-bold text-primary">{{i18n.asOfHarvestDate}} : {{`${selectYieldCropNmList.filter(crop => crop.value == this.selectYieldCropNo)[0].text} ${yieldStartYmd == null ? '':yieldStartYmd} ~ ${yieldEndYmd == null ? '':yieldEndYmd}`}}</span>
            </div>
            <div class="d-flex align-items-end flex-wrap">
              <b-list-group horizontal class="mb-5" style="margin-right: auto;">
                <b-list-group-item
                  v-for="(dateRange) in fixedDateRangeYield.dateRanges"
                  button
                  :key="dateRange.key"
                  :active="fixedDateRangeYield.activeDateRange === dateRange.key"
                  @click="[onFixedDateRangeChanged(dateRange.key, true), yieldPaging.pageNo=1, yieldlinkGen(), getYieldTotalCrops()]"
                >
                  {{ dateRange.date }}
                </b-list-group-item>
                <b-list-group-item v-if="!isMobile" button @click="onDownloadExcel()" :title="i18n.downloadExcel">
                  <i class="far fa-file-excel"></i>
                </b-list-group-item>
              </b-list-group>
            </div>
           </div>
            <v-data-table
                :headers="totalYieldFields"
                :items="totalYieldItems"
                class="elevation-1 farm-data-table"
                :no-data-text="`${i18n.noData}`"
                hide-default-footer
            >
            </v-data-table>
            <!--begin::pagination-->
            <div v-if="yieldTotalPage" class="pagination mt-12">
              <b-pagination-nav
                :number-of-pages="yieldTotalPage"
                :link-gen="yieldlinkGen"
                use-router
                limit="10"
              >
                <template #first-text><i class="fas fa-angle-double-left"></i></template>
                <template #prev-text><i class="fas fa-angle-left"></i></template>
                <template #next-text><i class="fas fa-angle-right"></i></template>
                <template #last-text><i class="fas fa-angle-double-right"></i></template>
                <template #page="{ page, active }">
                  <span v-if="active" class="font-size-sm font-weight-bold text-white">{{ page }}</span>
                  <span v-else class="font-size-sm font-weight-bold text-dark">{{ page }}</span>
                </template>
              </b-pagination-nav>
            </div>
            <!--end::pagination-->
          </div>
          <!--end::yieldInfo-table-->
        </b-tabs>
      </div>
      </div>
      <!--end::Body-->
    </div>

    <!-- begin::yieldPlanModal -->
    <b-modal
      v-model="yieldPlanModal"
      size="xxl"
      hide-footer
      hide-header
      no-close-on-backdrop
    >
      <div class="card card-custom card-stretch">
      <!--begin::Header-->
      <div class="card-header border-0 align-items-center">
        <h3 class="card-title align-items-start flex-column">
        <span class="title font-weight-bold">
          {{ farmYieldPlanNo === 0 ? i18n.addPlan : this.cropNm + ' ' + i18n.edit}}
        </span>
        </h3>
        <div class="card-toolbar toolbar-symbol">
          <b-button variant="dark" class="btn-icon rounded-lg" size="sm" @click="yieldPlanModal = false">
            <i class="la la-close font-size-h5"></i>
          </b-button>
        </div>
      </div>
      <!--end::Header-->
      <!-- begin::Body -->
      <div class="card-body pt-5">
        <div class="form-group row mb-4">
          <label class="col-sm-2 col-form-label">
            <span class="h5">{{i18n.crop}}</span>
          </label>
          <div class="col-sm-4">
            <b-form-select v-model="cropNo" :options="cropNmList" @change="[setDays(), seedingDayChanged()]"></b-form-select>
          </div>
          <label class="col-sm-2 col-form-label">
            <span class="h5">{{i18n.sowingDate}}</span>
          </label>
          <div class="col-sm-4">
            <b-form-datepicker
              v-model="seedingDay"
              menu-class="w-"
              calendar-width="100%"
              class="mb-2"
              :locale="locale"
              :placeholder="`${i18n.yearMonthDay}`"
              @input="seedingDayChanged()"
            ></b-form-datepicker>
          </div>
        </div>

        <div class="form-group row mb-4">
          <label class="col-sm-2 col-form-label">
            <span class="h5">{{i18n.seedlingDate}}</span>
          </label>
          <label class="col-sm-4">
            <b-form-datepicker
              v-model="seedlingDay"
              menu-class="w-"
              calendar-width="100%"
              class="mb-2"
              :locale="locale"
              :placeholder="`${i18n.yearMonthDay}`"
            ></b-form-datepicker>
          </label>
          <label class="col-sm-2 col-form-label">
            <span class="h5">{{i18n.transplantDate}}</span>
          </label>
          <label class="col-sm-4">
            <b-form-datepicker
              v-model="transplantDay"
              menu-class="w-"
              calendar-width="100%"
              class="mb-2"
              :locale="locale"
              :placeholder="`${i18n.yearMonthDay}`"
            ></b-form-datepicker>
          </label>
        </div>

        <div class="form-group row mb-4">
          <label class="col-sm-2 col-form-label">
            <span class="h5">{{i18n.plantDate}}</span>
          </label>
          <label class="col-sm-4">
            <b-form-datepicker
              v-model="lastfeedDay"
              menu-class="w-"
              calendar-width="100%"
              class="mb-2"
              :locale="locale"
              :placeholder="`${i18n.yearMonthDay}`"
            ></b-form-datepicker>
          </label>
          <label class="col-sm-2 col-form-label">
            <span class="h5">{{i18n.harvestDateExpected}}</span>
          </label>
          <label class="col-sm-4">
            <b-form-datepicker
              v-model="yieldPlanDay"
              menu-class="w-"
              calendar-width="100%"
              class="mb-2"
              :locale="locale"
              :placeholder="`${i18n.yearMonthDay}`"
            ></b-form-datepicker>
          </label>
        </div>
        <div class="form-group row mb-4">
          <label class="col-sm-2 col-form-label">
            <span class="h5">{{i18n.nutiritionController}}</span>
          </label>
          <div class="col-sm-4">
            <b-form-select v-model="systemId" :options="connectNmList" @change="[setRack(), checkRackLocation()]"></b-form-select>
          </div>
          <label class="col-sm-2 col-form-label">
            <span class="h5">{{i18n.rackLocation}}</span>
          </label>
          <div class="col-sm-4">
            <div class="input-group" @click="checkConnect()">
            <b-form-input v-model="rackLocation" list="my-list-id" @blur="checkRackLocation()" :disabled="rackLocationValidation"></b-form-input>
            <datalist id="my-list-id" >
              <option v-for="rackOption in rackOptions" :key='rackOption'>{{ rackOption }}</option>
            </datalist>
            <div class="input-group-append">
            <button class="btn btn-secondary" @click="showRackModal()">{{i18n.addRack}}</button>
            <button class="btn btn-secondary" @click="showRackDeleteModal()">{{i18n.deleteRack}}</button>
            </div>
            </div>
          </div>
        </div>
        <div class="form-group row mb-4">
          <label class="col-sm-2 col-form-label">
            <span class="h5">{{i18n.potCount}}</span>
          </label>
          <div class="col-sm-4">
            <input v-model="lastfeedPotCount" type="number" class="farm-form-control form-control" @input="setYieldPlanAmount()">
          </div>
          <label class="col-sm-2 col-form-label">
            <span class="h5">{{i18n.targetYield}}</span>
          </label>
          <div class="col-sm-4">
            <input v-model="yieldPlanAmount" type="number" class="farm-form-control form-control">
          </div>
        </div>
      </div>
      <!--begin::Footer-->
          <div class="card-footer">
            <b-button variant="primary" size="lg" @click="updateYieldPlanModal()"><span >{{ farmYieldPlanNo == 0 ? i18n.register : i18n.edit }}</span></b-button>
            <b-button variant="secondary" size="lg" class="ml-3" @click="yieldPlanModal = false"><span class="">{{i18n.close}}</span></b-button>
          </div>
      <!--end::Footer-->
      </div>
    </b-modal>
    <!-- end::yieldPlanModal -->


    <!-- begin::yieldAddModal -->
    <b-modal
      v-model="yieldAddModal"
      size="xl"
      hide-footer
      hide-header
      no-close-on-backdrop
    >
    <div class="card card-custom card-stretch">
      <!--begin::Header-->
      <div class="card-header border-0 align-items-center">
        <h3 class="card-title align-items-start flex-column">
        <span class="title font-weight-bold">
          {{ farmYieldNo === 0 ? i18n.yieldRegistration : i18n.editYieldRegistration }}
        </span>
        </h3>
        <div class="card-toolbar toolbar-symbol">
          <b-button variant="dark" class="btn-icon rounded-lg" size="sm" @click="yieldAddModal = false">
            <i class="la la-close font-size-h5"></i>
          </b-button>
        </div>
      </div>
      <!--end::Header-->
      <!-- begin::Body -->
      <div class="card-body pt-5">
        <div class="form-group row mb-4">
          <label class="col-sm-2 col-form-label">
            <span class="h5">{{i18n.harvestDate}}</span>
          </label>
          <div class="col-sm-4">
            <b-form-datepicker
              v-model="yieldDay"
              menu-class="w-"
              calendar-width="100%"
              class="mb-2"
              :locale="locale"
              :placeholder="`${i18n.yearMonthDay}`"
              @input="setGrowPeriod()"
            ></b-form-datepicker>
          </div>
          <label class="col-sm-2 col-form-label">
            <span class="h5">{{i18n.cultivationPeriod}}</span>
          </label>
          <div class="col-sm-4">
            <input v-model="yieldGrowPeriod" type="number" class="farm-form-control form-control" @input="setYieldDay()">
          </div>
        </div>

        <div class="form-group row mb-4">
          <label class="col-sm-2 col-form-label">
            <span class="h5">{{i18n.headNumber}}</span>
          </label>
          <div class="col-sm-4">
            <input v-model="yieldPotCount" type="number" class="farm-form-control form-control" @input="calTotalWeight()">
          </div>
          <label class="col-sm-2 col-form-label">
            <span class="h5">{{i18n.weightPerHead}}</span>
          </label>
          <div class="col-sm-4">
            <input v-model="yieldAvgWeight" type="number" class="farm-form-control form-control" @input="calTotalWeight()">
          </div>
        </div>

        <div class="form-group row mb-4">
          <label class="col-sm-2 col-form-label">
            <span class="h5">{{i18n.totalWeight}}</span>
          </label>
          <div class="col-sm-4">
            <input v-model="yieldAmount" type="number" class="farm-form-control form-control" @input='setAvgWeight()'>
          </div>
          <label v-show="cropNo == 10003" class="col-sm-2 col-form-label">
            <span class="h5">{{i18n.grade}}</span>
          </label>
          <div v-show="cropNo == 10003" class="col-sm-4">
            <input v-model="yieldGrade" type="text" class="farm-form-control form-control">
          </div>
        </div>

        <div class="form-group row mb-4">
          <label class="col-sm-2 mb-2 col-form-label">
            <span class="h5">{{i18n.cultivateIssue}}</span>
          </label>
          <textarea
            v-model="yieldIssue"
            :placeholder="`${i18n.typeCultivateIssue}`"
            rows="5"
            max-rows="10"
            wrap="soft"
            class="farm-form-control form-control" >
          </textarea>
        </div>
        </div>
        <!--begin::Footer-->
          <div class="card-footer">
            <b-button variant="primary" size="lg" @click="updateYieldAddModal()"><span >{{ farmYieldNo === 0 ? i18n.register : i18n.edit }}</span></b-button>
            <b-button variant="secondary" size="lg" class="ml-3" @click="yieldAddModal = false"><span class="">{{i18n.close}}</span></b-button>
          </div>
        <!--end::Footer-->
      </div>

    </b-modal>
    <!-- end::yieldAddModal -->

    <!-- begin::rackModal -->
    <b-modal
      v-model="rackModal"
      size="xl"
      hide-footer
      hide-header
      no-close-on-backdrop
    >
    <div class="card card-custom card-stretch">
      <!--begin::Header-->
      <div class="card-header border-0 align-items-center">
        <h3 class="card-title align-items-start flex-column">
        <span class="title font-weight-bold">
          {{i18n.addRack}}
        </span>
        </h3>
        <div class="card-toolbar toolbar-symbol">
          <b-button variant="dark" class="btn-icon rounded-lg" size="sm" @click="rackModal = false">
            <i class="la la-close font-size-h5"></i>
          </b-button>
        </div>
      </div>
      <!--end::Header-->
      <!-- begin::Body -->
      <div class="card-body pt-5">
        <div class="form-group row mb-4">
          <label class="col-sm-2 col-form-label">
            <span class="h5">{{i18n.rackNm}}</span>
          </label>
         <div class="col-sm-4">
            <input v-model="rackNm" type="text" class="farm-form-control form-control" placeholder="ex) A1, A2, B1...">
          </div>
          <label class="col-sm-2 col-form-label">
            <span class="h5">{{i18n.controller}}</span>
          </label>
          <div class="col-sm-4">
            <b-form-select v-model="rackConnect" :options="connectNmList"></b-form-select>
          </div>
        </div>
        <div class="form-group row mb-4">
          <label class="col-sm-2 col-form-label">
            <span class="h5">{{i18n.rackRowCount}}</span>
          </label>
          <div class="col-sm-4">
            <input v-model="rackRowCount" type="number" class="farm-form-control form-control">
          </div>
          <label class="col-sm-2 col-form-label">
            <span class="h5">{{i18n.rackColumnCount}}</span>
          </label>
          <div class="col-sm-4">
            <input v-model="rackColumnCount" type="number" class="farm-form-control form-control">
          </div>
        </div>
        <div class="form-group row mb-4">
          <label class="col-sm-2 col-form-label">
            <span class="h5">{{i18n.potCount}}</span>
          </label>
          <div class="col-sm-4">
            <input v-model="rackPotCount" type="number" class="farm-form-control form-control">
          </div>
        </div>
      </div>
      <!--begin::Footer-->
          <div class="card-footer">
            <b-button variant="primary" size="lg" @click="updateRackModal"><span>{{i18n.add}}</span></b-button>
            <b-button variant="secondary" size="lg" class="ml-3" @click="rackModal = false"><span class="">{{i18n.close}}</span></b-button>
          </div>
      <!--end::Footer-->
      </div>
    </b-modal>
    <!-- end::rackModal -->

    <!-- begin::rackDeleteModal -->
    <b-modal
      v-model="rackDeleteModal"
      size="xl"
      hide-footer
      hide-header
      no-close-on-backdrop
    >
    <div class="card card-custom card-stretch">
      <!--begin::Header-->
      <div class="card-header border-0 align-items-center">
        <h3 class="card-title align-items-start flex-column">
        <span class="title font-weight-bold">
          {{i18n.deleteRack}}
        </span>
        </h3>
        <div class="card-toolbar toolbar-symbol">
          <b-button variant="dark" class="btn-icon rounded-lg" size="sm" @click="rackDeleteModal = false">
            <i class="la la-close font-size-h5"></i>
          </b-button>
        </div>
      </div>
      <!--end::Header-->
      <!-- begin::Body -->
      <div class="card-body pt-5">
        <div class="form-group row mb-4">
          <label class="col-sm-3 col-form-label">
            <span class="h5">{{i18n.selectRackForDelete}}</span>
          </label>
          <div class="col-sm-4">
            <b-form-input v-model="rackDeleteLocation" list="my-list-id2"></b-form-input>
            <datalist id="my-list-id2">
              <option v-for="rackOption in rackDeleteOptions" :key='rackOption'>{{ rackOption }}</option>
            </datalist>
          </div>
        </div>
      </div>
      <!--begin::Footer-->
          <div class="card-footer">
            <b-button variant="primary" size="lg" @click="updateRackDeleteModal()"><span>{{i18n.delete}}</span></b-button>
            <b-button variant="secondary" size="lg" class="ml-3" @click="rackDeleteModal = false"><span>{{i18n.close}}</span></b-button>
          </div>
      <!--end::Footer-->
      </div>
    </b-modal>
    <!-- end::rackDeleteModal -->

    <!-- begin::TableForExcelDownload -->
    <table v-show="false" class="table table-vertical-center dtr-inline text-center table-condensed" id="yield_table">
      <thead class="thead-light">
      <tr role="row">
        <th rowspan="2">{{i18n.farmName}}</th>
        <th rowspan="2">{{i18n.cropName}}</th>
        <th rowspan="1" colspan="13">{{i18n.yieldData}}</th>
      </tr>
      <tr role="row">
        <th v-for="item in yieldExcelheader" :key="item" class="sorting" rowspan="1" colspan="1">{{item}}</th>
      </tr>
      </thead>
      <tbody>
      <tr v-if="!excelCrops.length">
        <td colspan="15" style="text-align: center">
          <span><br>No Data<br><br></span>
        </td>
      </tr>
      <tr :key="idx" v-for="(item, idx) in excelCrops">
        <td>{{ farmNm ? farmNm : ''}}</td>
        <td>{{ locale == 'ko' ? (item.cropNm ? item.cropNm : '') : (item.cropNmEn ? item.cropNmEn : '')}}</td>
        <td>{{ item.seedingDay ? item.seedingDay : ''}}</td>
        <td>{{ item.seedlingDay ? item.seedlingDay : ''}}</td>
        <td>{{ item.transplantDay ? item.transplantDay : ''}}</td>
        <td>{{ item.lastfeedDay ? item.lastfeedDay : ''}}</td>
        <td>{{ item.yieldDay ? item.yieldDay : ''}}</td>
        <td>{{ item.growPeriod ? item.growPeriod : ''}}</td>
        <td>{{ item.yieldAmount ? item.yieldAmount : ''}}</td>
        <td>{{ item.yieldPotCount ? item.yieldPotCount : ''}}</td>
        <td>{{ item.yieldAvgWeight ? item.yieldAvgWeight : ''}}</td>
        <td>{{ item.connectNm ? item.connectNm : ''}}</td>
        <td>{{ item.rackLocation ? item.rackLocation : ''}}</td>
        <td>{{ item.yieldGrade ? item.yieldGrade : ''}}</td>
        <td>{{ item.issue ? item.issue : ''}}</td>
      </tr>
      </tbody>
    </table>
    <!-- end::TableForExcelDownload -->

  </div>
</template>

<script>
import {SET_BREADCRUMB} from '@/core/services/store/breadcrumbs.module';
import {ACT_GET_MY_FARM,
ACT_GET_MY_FARM_YIELD_PLAN,
ACT_INSERT_MY_FARM_YIELD_PLAN,
ACT_UPDATE_MY_FARM_YIELD_PLAN,
ACT_DELETE_MY_FARM_YIELD_PLAN,
ACT_GET_MY_FARM_YIELD,
ACT_INSERT_MY_FARM_YIELD,
ACT_UPDATE_MY_FARM_YIELD,
ACT_DELETE_MY_FARM_YIELD,
ACT_GET_MY_FARM_TOTAL_YIELD,
ACT_GET_MY_FARM_RACK,
ACT_INSERT_MY_FARM_RACK,
ACT_DELETE_MY_FARM_RACK,
} from '@/core/services/variable';
import {isSuccess, getResult, dateAddDel, lan, utcToDate} from '@/core/services/funcs';
import {ACT_ADD_ALERT, ACT_SHOW_CONFIRM_ALERT, ACT_SHOW_ERROR_ALERT} from '@/core/services/store/alert.module'; //ACT_SHOW_ERROR_ALERT
import TFarmonTableHeader from '@/components/TFarmonTableHeader';
import i18n from '@/core/plugins/vue-i18n.js';
import Vue from "vue";
import {mapGetters} from 'vuex';
import * as XLSX from 'xlsx'

export default {
  name: "YieldPlanInfomation",
  components: {
    TFarmonTableHeader
  },
  beforeMount(){
    this.changeFixedDate(30)
    this.changeFixedDateYield(30)
    if(Object.keys(i18n._localeChainCache)[0] == 'en'){
      this.locale = 'en'
    }

    this.$store.dispatch(ACT_GET_MY_FARM).then(resp => {
      this.fno = resp.items[0].fno
      this.farmNm = resp.items[0].farmNm
      resp.items[0].farmConnects.forEach((item)=>{
          this.connectNmList.push({value:item.systemId ,text: item.connectNm})
          this.rackConnectNmList.push({value:item.systemId ,text: item.connectNm})
      })
      this.getYieldPlans()
      this.getYieldTotalCrops()
      this.getRack()
      resp.items[0].farmCultivateCrops.forEach((item)=>{
        if(this.cropNmList.filter(crop => crop.value == item.cropNo).length == 0){
          this.cropNmList.push({value:item.cropNo ,text: lan() == 'ko' ? item.cropNm : item.cropNmEn})
          this.selectCropNmList.push({value:item.cropNo ,text: lan() == 'ko' ? item.cropNm : item.cropNmEn})
          this.selectYieldCropNmList.push({value:item.cropNo ,text: lan() == 'ko' ? item.cropNm : item.cropNmEn})
          this.cropEnvList.push({value: item.cropNo, text: JSON.parse(item.cropEnvJson)})
        }
      })
      const params = {
        startYmd: null,
        endYmd: null,
        pageNo: 1,
        pageSize: 99999,
      }
      this.$store.dispatch(ACT_GET_MY_FARM_TOTAL_YIELD, params).then(resp => {
        resp.items.forEach(item =>{
          if(this.selectCropNmList.filter(crop => crop.value == item.cropNo).length == 0){
            this.selectCropNmList.push({value:item.cropNo ,text: lan() == 'ko' ? item.cropNm : item.cropNmEn})
            this.selectYieldCropNmList.push({value:item.cropNo ,text: lan() == 'ko' ? item.cropNm : item.cropNmEn})
          }
        })
      })
    })

  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: `${i18n.t('farm.yield.farmInfo')}`, icon: 'fas fa-clipboard' }, { title: `${i18n.t('farm.yield.yieldManagement')}` }]);
    window.scrollTo(0,0);
  },
  created() {
    if(this.$route.query.page) this.paging.pageNo = this.$route.query.page;
    if(this.$route.query.yieldPage) this.yieldpaging.pageNo = this.$route.query.yieldPage;
  },
  watch: {
    '$route' (to) {
      if(to.query.page){
        this.paging.pageNo = to.query.page;
        this.getYieldPlans()
      }else {
        this.paging.pageNo = 1
        this.getYieldPlans()
      }
      if(to.query.yieldPage){
        this.yieldPaging.pageNo = to.query.yieldPage;
        this.getYieldTotalCrops()
      }else{
        this.yieldPaging.pageNo = 1
        this.getYieldTotalCrops()
      }
    },
  },
  data() {
    return {
       i18n: {
        registerPlanAndYield: i18n.t('farm.yield.registerPlanAndYield'),
        planRegisteration: i18n.t('farm.yield.planRegisteration'),
        cropName: i18n.t('farm.yield.cropNm'),
        startDate: i18n.t('farm.yield.start'),
        endDate: i18n.t('farm.yield.end'),
        viewAll: i18n.t('farm.yield.viewAll'),
        downloadExcel: i18n.t('farm.yield.downloadExcel'),
        delete: i18n.t('farm.yield.delete'),
        numbering: i18n.t('farm.cultivationCrop.numbering'),
        rackLocation: i18n.t('farm.facility.rackLocation'),
        rackCount: i18n.t('farm.facility.rackCount'),
        nutiritionController: i18n.t('farm.yield.nutiritionController'),
        sowingDate: i18n.t('farm.yield.sowingDate'),
        seedlingDate: i18n.t('farm.yield.seedlingDate'),
        transplantDate: i18n.t('farm.yield.transplantDate'),
        plantDate: i18n.t('farm.yield.plantDate'),
        harvestDate: i18n.t('farm.yield.harvestDate'),
        growthPeriod: i18n.t('farm.cultivationCropMangement.growthPeriod'),
        totalWeight: i18n.t('farm.yield.totalWeight'),
        headNumber: i18n.t('farm.yield.headNumber'),
        weightPerHead: i18n.t('farm.yield.weightPerHead'),
        grade: i18n.t('farm.yield.grade'),
        filterState: i18n.t('farm.yield.filterState'),
        crop: i18n.t('farm.yield.crop'),
        issue: i18n.t('farm.yield.issue'),
        addRack: i18n.t('farm.yield.addRack'),
        deleteRack: i18n.t('farm.yield.deleteRack'),
        controller: i18n.t('farm.yield.controller'),
        cultivateIssue: i18n.t('farm.yield.cultivateIssue'),
        typeCultivateIssue: i18n.t('farm.yield.typeCultivateIssue'),
        register: i18n.t('farm.cultivationCrop.register'),
        close: i18n.t('cultivationEnvControlSetting.close'),
        yearMonthDay: i18n.t('farm.yield.yearMonthDay'),
        noData: i18n.t('farm.yield.noData'),
        noPlanData: i18n.t('farm.yield.noPlanData'),
        rackNm: i18n.t('farm.yield.rackNm'),
        rackRowCount: i18n.t('farm.yield.rackRowCount'),
        rackColumnCount: i18n.t('farm.yield.rackColumnCount'),
        potCount: i18n.t('farm.yield.potCount'),
        add: i18n.t('claim.registerClaim.add'),
        selectRackForDelete: i18n.t('farm.yield.selectRackForDelete'),
        sureDelete: i18n.t('farm.yield.sureDelete'),
        cropDelete: i18n.t('farm.yield.cropDelete'),
        addRackSuccess: i18n.t('farm.yield.addRackSuccess'),
        rackLocationDuplicated: i18n.t('farm.yield.rackLocationDuplicated'),
        enterAllValue: i18n.t('farm.yield.enterAllValue'),
        successDelete: i18n.t('farm.yield.successDelete'),
        addCropSuccess: i18n.t('farm.yield.addCropSuccess'),
        rightRack: i18n.t('farm.yield.rightRack'),
        usedRack: i18n.t('farm.yield.usedRack'),
        deleteRackSuccess: i18n.t('farm.yield.deleteRackSuccess'),
        realNoData: i18n.t('farm.yield.realNoData'),
        selectCropNm: i18n.t('farm.yield.selectCropNm'),
        addPlan: i18n.t('farm.yield.addPlan'),
        asOfPlantDate: i18n.t('farm.yield.asOfPlantDate'),
        asOfHarvestDate: i18n.t('farm.yield.asOfHarvestDate'),
        harvestDateExpected: i18n.t('farm.yield.harvestDateExpected'),
        yieldRegistration: i18n.t('farm.yield.yieldRegistration'),
        editYieldRegistration: i18n.t('farm.yield.editYieldRegistration'),
        viewYield: i18n.t('farm.yield.viewYield'),
        cultivationPeriod: i18n.t('farm.yield.cultivationPeriod'),
        deleteMessage: i18n.t('farm.yield.deleteMessage'),
        expected: i18n.t('farm.yield.expected'),
        targetYield: i18n.t('farm.yield.targetYield'),
        edit: i18n.t('farm.yield.edit'),
        farmName: i18n.t('farm.yield.farmName'),
        yieldData: i18n.t('farm.yield.yieldData'),
        excelDownloadComplete: i18n.t('farm.yield.excelDownloadComplete'),
        cropD1: i18n.t('farm.yield.cropD1'),
        cropD2: i18n.t('farm.yield.cropD2'),
        cropD3: i18n.t('farm.yield.cropD3'),
        confirm: i18n.t('farm.yield.confirm'),
        rightValue: i18n.t('farm.yield.rightValue'),
       },
      cropsExpand: {},

      selectCropNo: 1,
      selectCropNmList:[{value:1, text:`${i18n.t('farm.yield.allCrops')}`}],
      selectYieldCropNo: 1,
      selectYieldCropNmList:[{value:1, text:`${i18n.t('farm.yield.allCrops')}`}],
      startYmd: null,
      endYmd: null,
      yieldStartYmd: null,
      yieldEndYmd: null,

      yieldExcelheader: [i18n.t('farm.yield.sowingDate'),i18n.t('farm.yield.seedlingDate'),i18n.t('farm.yield.transplantDate'),i18n.t('farm.yield.plantDate'),
                        i18n.t('farm.yield.harvestDate'),i18n.t('farm.yield.cultivationPeriod'),i18n.t('farm.yield.totalWeight'),i18n.t('farm.yield.headNumber'),
                        i18n.t('farm.yield.weightPerHead'),i18n.t('farm.yield.controller'),i18n.t('farm.yield.rackLocation'),i18n.t('farm.yield.grade'),i18n.t('farm.yield.issue')],


      yieldContent: [i18n.t('farm.yield.registerPlanAndYield'), i18n.t('farm.yield.totalRegistration')],
      tableState: 0,

      yieldPlanFields: [
        { value: 'idx', text: i18n.t('farm.cultivationCropMangement.numbering'), width: '80px' },
        { value: 'fieldA', text: i18n.t('farm.cultivationCropMangement.cropName')},
        { value: 'fieldB', text: i18n.t('farm.yield.sowingDate')},
        { value: 'fieldC', text: i18n.t('farm.yield.seedlingDate')},
        { value: 'fieldD', text: i18n.t('farm.yield.transplantDate')},
        { value: 'fieldE', text: i18n.t('farm.yield.plantDate')},
        { value: 'fieldF', text: i18n.t('farm.yield.harvestDateExpected')},
        { value: 'fieldG', text: i18n.t('farm.yield.controller')},
        { value: 'fieldH', text: i18n.t('farm.yield.rackLocation')},
        { value: 'fieldI', text: i18n.t('farm.yield.potCount')},
        { value: 'fieldJ', text: i18n.t('farm.yield.targetYield')},
        { value: 'fieldK', text: i18n.t('farm.yield.currentYield')},
        { value: 'fieldL', text: i18n.t('farm.yield.editPlan'),width: '120px' },
        { value: 'fieldM', text: i18n.t('farm.yield.yieldRegistration'),width: '120px' },
        { value: 'data-table-expand', text: i18n.t('farm.yield.viewYield'), width: '50px' },
      ],
      yieldFields: [
        { value: 'fieldA', text: i18n.t('farm.yield.harvestDate')},
        { value: 'fieldB', text: i18n.t('farm.yield.cultivationPeriod')},
        { value: 'fieldC', text: i18n.t('farm.yield.headNumber')},
        { value: 'fieldD', text: i18n.t('farm.yield.weightPerHead')},
        { value: 'fieldE', text: i18n.t('farm.yield.totalWeight')},
        { value: 'fieldF', text: i18n.t('farm.yield.grade')},
        { value: 'fieldG', text: i18n.t('farm.yield.issue')},
        { value: 'fieldH', text: '',width: '120px' },
      ],
      totalYieldFields: [
        { value: 'idx', text: i18n.t('farm.cultivationCropMangement.numbering'), width: '80px' },
        { value: 'fieldA', text: i18n.t('farm.cultivationCropMangement.cropName') },
        { value: 'fieldB', text: i18n.t('farm.yield.sowingDate')},
        { value: 'fieldC', text: i18n.t('farm.yield.seedlingDate')},
        { value: 'fieldD', text: i18n.t('farm.yield.transplantDate')},
        { value: 'fieldE', text: i18n.t('farm.yield.plantDate')},
        { value: 'fieldF', text: i18n.t('farm.yield.harvestDate')},
        { value: 'fieldG', text: i18n.t('farm.yield.cultivationPeriod')},
        { value: 'fieldH', text: i18n.t('farm.yield.totalWeight')},
        { value: 'fieldI', text: i18n.t('farm.yield.headNumber')},
        { value: 'fieldJ', text: i18n.t('farm.yield.weightPerHead')},
        { value: 'fieldK', text: i18n.t('farm.yield.controller')},
        { value: 'fieldL', text: i18n.t('farm.yield.rackLocation')},
        { value: 'fieldM', text: i18n.t('farm.yield.grade')},
        { value: 'fieldN', text: i18n.t('farm.yield.issue')},
      ],

      paging: {
        pageNo: 1,
        pageSize: 10,
        totalCount: 1,
        hasMore: false
      },
      crops: [],
      yieldCrops: [],
      TotalYieldCrops: [],
      excelCrops: [],
      yieldPlanModal: false,
      yieldAddModal: false,

      fno: 0,
      farmNm: '',
      cropIdx: 0,
      cropNo: 0,
      cropNm: '',
      cropNmList: [],
      systemId: 0,
      connectNm: '',
      connectNmList: [],
      rackLocation: '',
      rackCount: 0,
      seedingDay: '',
      seedlingDay:'',
      transplantDay: '',
      lastfeedDay: '',
      yieldDay: '',
      yieldGrowPeriod: 0,
      yieldAmount: 0,
      yieldPotCount: 0,
      yieldAvgWeight: 0,
      yieldGrade: '',
      yieldIssue: '',
      racks : [],
      rackOptions: [],
      managementNo: '',
      farmYieldNo: 0,
      cropEnv: {
        seedMedianDay: 0,
        transplantMedianDay: 0,
        lastfeedMedianDay: 0
      },
      cropEnvList: [],
      nullCheck: true,
      rackModal: false,
      rackDeleteModal: false,
      rackNm: '',
      rackConnect: '',
      rackRowCount: 0,
      rackColumnCount: 0,
      rackPotCount: 0,
      rackConnectNmList: [],
      growType: '정식',
      rackDeleteLocation: '',
      rackDeleteOptions: [],
      rackLocationValidation: true,

      farmYieldPlanNo: 0,
      yieldPlanDay: '',
      yieldPlanAmount: 0,
      lastfeedPotCount: 0,

      yieldPaging: {
        pageNo: 1,
        pageSize: 10,
        totalCount: 1,
        hasMore: false
      },

      fixedDateRange: {
        activeDateRange: 'month',
        dateRanges: [
          { key: 'day', date: i18n.t('farm.timeseries.day') },
          { key: 'week', date: i18n.t('farm.timeseries.week') },
          { key: 'month', date: i18n.t('farm.timeseries.month') }
        ]
      },
      fixedDateRangeYield: {
        activeDateRange: 'month',
        dateRanges: [
          { key: 'day', date: i18n.t('farm.timeseries.day') },
          { key: 'week', date: i18n.t('farm.timeseries.week') },
          { key: 'month', date: i18n.t('farm.timeseries.month') }
        ]
      },
      locale: 'ko',
    }
  },
  computed: {
    ...mapGetters(["isMobile"]),
    totalPage() {
      if(this.paging){
        return Math.ceil(this.paging.totalCount / this.paging.pageSize);
      }
      return 0;
    },
    yieldTotalPage() {
      if(this.yieldPaging){
        return Math.ceil(this.yieldPaging.totalCount / this.yieldPaging.pageSize);
      }
      return 0;
    },
    yieldPlanItems() {
      const items = [];

      this.crops.forEach((crop, idx) => {
        items.push({
          'idx': this.getCountIdx(idx),
          'editMode': false,
          'fieldA' : lan() == 'ko' ? crop.cropNm :  crop.cropNmEn,
          'fieldB' : crop.seedingDay,
          'fieldC' : crop.seedlingDay,
          'fieldD' : crop.transplantDay,
          'fieldE' : crop.lastfeedDay,
          'fieldF' : crop.yieldPlanDay,
          'fieldG' : crop.connectNm,
          'fieldH' : crop.rackLocation,
          'fieldI' : crop.lastfeedPotCount,
          'fieldJ' : crop.yieldPlanAmount,
          'fieldK' : crop.yieldRate.toFixed(0),
          'farmYieldPlanNo' : crop.farmYieldPlanNo,
          'cropNo': crop.cropNo,
          'systemId': crop.systemId,
        })
      });
      return items;
    },
    totalYieldItems() {
      const items = [];

      this.TotalYieldCrops.forEach((crop, idx) => {
        items.push({
          'idx': this.yieldGetCountIdx(idx),
          'editMode': false,
          'fieldA' : lan() == 'ko' ? crop.cropNm :  crop.cropNmEn,
          'fieldB' : crop.seedingDay,
          'fieldC' : crop.seedlingDay,
          'fieldD' : crop.transplantDay,
          'fieldE' : crop.lastfeedDay,
          'fieldF' : crop.yieldDay,
          'fieldG' : crop.growPeriod,
          'fieldH' : crop.yieldAmount,
          'fieldI' : crop.yieldPotCount,
          'fieldJ' : crop.yieldAvgWeight,
          'fieldK' : crop.connectNm,
          'fieldL' : crop.rackLocation,
          'fieldM' : crop.yieldGrade != "" ? crop.yieldGrade : '-',
          'fieldN' : crop.issue !='' ? (crop.issue.length <= 12 ? crop.issue : crop.issue.substring(0,12)+"....") : "",
          'farmYieldNo' : crop.farmYieldNo,
          'issue': crop.issue,
          'cropNo': crop.cropNo,
        })
      });
      return items;
    },
  },
  methods: {
    onFixedDateRangeChanged(dateRange, isYield) {
      if (dateRange === this.fixedDateRange.dateRanges[0].key) {
        if(!isYield) this.changeFixedDate(1)
        if(isYield) this.changeFixedDateYield(1)
      } else if (dateRange === this.fixedDateRange.dateRanges[1].key) {
        if(!isYield) this.changeFixedDate(7)
        if(isYield) this.changeFixedDateYield(7)
      } else if (dateRange === this.fixedDateRange.dateRanges[2].key) {
        if(!isYield) this.changeFixedDate(30)
        if(isYield) this.changeFixedDateYield(30)
      }
      if(!isYield) this.fixedDateRange.activeDateRange = dateRange
      if(isYield) this.fixedDateRangeYield.activeDateRange = dateRange
    },
    changeFixedDate(day) {
      let currentDate = new Date();
      const startDate = new Date(currentDate.setDate(currentDate.getDate() - day ));
      const startDateUTC = new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()));
      this.startYmd = startDateUTC.toISOString().slice(0, 10)
      this.endYmd = utcToDate(new Date(), 'yyyy-MM-dd')
    },
    changeFixedDateYield(day) {
      let currentDate = new Date();
      const startDate = new Date(currentDate.setDate(currentDate.getDate() - day ));
      const startDateUTC = new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()));
      this.yieldStartYmd = startDateUTC.toISOString().slice(0, 10);
      this.yieldEndYmd = utcToDate(new Date(), 'yyyy-MM-dd')
    },
    yieldItemsExpand(item, header){
      const items = [];
      if(this.cropsExpand[item.farmYieldPlanNo] != undefined){
        this.cropsExpand[item.farmYieldPlanNo].forEach((crop) => {
          items.push({
            'editMode': false,
            'fieldA' : crop.yieldDay,
            'fieldB' : crop.growPeriod,
            'fieldC' : crop.yieldPotCount,
            'fieldD' : crop.yieldAvgWeight,
            'fieldE' : crop.yieldAmount,
            'fieldF' : crop.yieldGrade != "" ? crop.yieldGrade : '-',
            'fieldG' : crop.issue !='' ? (crop.issue.length <= 20 ? crop.issue : crop.issue.substring(0,20)+"....") : "",
            'farmYieldNo' : crop.farmYieldNo,
            'issue': crop.issue,
            'cropNo': crop.cropNo,
            'lastfeedPotCount': crop.lastfeedPotCount,
            'farmYieldPlanNo': crop.farmYieldPlanNo
          })
        })
      }

      return items
    },
    onDownloadExcel(){
      const yesCallback = () =>{
        this.excelCrops = []

        const params = {
        cropNo : this.selectYieldCropNo,
        startYmd: this.yieldStartYmd,
        endYmd: this.yieldEndYmd,
        pageNo: 1,
        pageSize: 99999,
        }
        if(this.selectYieldCropNo == 1){
          delete params.cropNo
        }

        this.$store.dispatch(ACT_GET_MY_FARM_TOTAL_YIELD, params).then(resp => {
          resp.items.forEach((item)=>{
            this.excelCrops.push(item)
          })
          if(this.excelCrops.length == 0){
            this.$store.dispatch(ACT_ADD_ALERT, {message: i18n.t('farm.yield.realNoData'), color: 'danger'});
            return
          }
          this.$store.dispatch(ACT_ADD_ALERT, {message: this.i18n.excelDownloadComplete, color: 'success'}).then(() =>{
            const workSheet = XLSX.utils.table_to_sheet(document.getElementById('yield_table'), {dateNF:'yyyy/mm/dd hh:mm:ss;@',cellDates:true, raw: true})

            const workBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workBook, workSheet, `sheet1`);
            XLSX.writeFile(workBook, `${this.farmNm} ${this.i18n.yieldData}.xlsx`);
          })
        })
      }
      this.$store.dispatch(ACT_SHOW_CONFIRM_ALERT, {
        info: {title: i18n.t('farm.yield.downloadYield')},
        callback: yesCallback
      })
    },
    getYieldTotalCrops(){
        const params = {
        cropNo : this.selectYieldCropNo,
        startYmd: this.yieldStartYmd,
        endYmd: this.yieldEndYmd,
        pageNo: this.yieldPaging.pageNo,
        pageSize: this.yieldPaging.pageSize,
        totalCount: this.yieldPaging.totalCount
        }
        if(this.selectYieldCropNo == 1){
          delete params.cropNo
        }
        this.TotalYieldCrops = []
        this.$store.dispatch(ACT_GET_MY_FARM_TOTAL_YIELD, params).then(resp => {
          resp.items.forEach((item)=>{
            this.TotalYieldCrops.push(item)
          })
          this.yieldPaging.totalCount = resp.paging.totalCount
        })
    },
    deleteYieldModal(item){
      const yesCallback = () =>{
        this.$store.dispatch(ACT_DELETE_MY_FARM_YIELD, item.farmYieldNo).then((resp) =>{
            if(isSuccess(resp)){
              this.$store.dispatch(ACT_ADD_ALERT, {message: i18n.t('farm.yield.deleteYield'), color: 'success'}).then(()=>{
                this.getYieldPlans()
                this.getYieldTotalCrops()
              })
            }else{
              this.$store.dispatch(ACT_ADD_ALERT, {message: getResult(resp).message});
            }
          })
      }
      this.$store.dispatch(ACT_SHOW_CONFIRM_ALERT, {
        info: {title: i18n.t('farm.yield.deleteYieldUnit')},
        callback: yesCallback
      })
    },
    updateYieldAddModal(){
      let params = {
        farmYieldPlanNo: this.farmYieldPlanNo,
        growPeriod: this.yieldGrowPeriod,
        issue: this.yieldIssue,
        yieldAmount: this.yieldAmount,
        yieldAvgWeight: this.yieldAvgWeight,
        yieldDay: this.yieldDay,
        yieldGrade: this.yieldGrade,
        yieldPotCount: this.yieldPotCount
      }
      let state = true

      Object.keys(params).forEach(key =>{
        if(key != 'yieldGrade' && key != 'issue' && key != 'farmYieldPlanNo'){
          if(params[key] == '' || params[key] == null || params[key] == 0){
            state = false
          }
        }
      })
      if(state == false){
        this.$store.dispatch(ACT_ADD_ALERT, {message: i18n.t('farm.yield.rightValue'), color: 'danger'})
        return
      }

      if(this.farmYieldNo === 0){
        this.$store.dispatch(ACT_INSERT_MY_FARM_YIELD, params).then(() => {
          this.$store.dispatch(ACT_ADD_ALERT, {message: i18n.t('farm.yield.addYield'), color: 'success'})
          this.yieldAddModal = false
          this.getYieldPlans()
          this.getYieldTotalCrops()
        })
      }else{
        delete params.farmYieldPlanNo
        this.$store.dispatch(ACT_UPDATE_MY_FARM_YIELD, {yno: this.farmYieldNo, params: params}).then(()=>{
          this.$store.dispatch(ACT_ADD_ALERT, {message: i18n.t('farm.yield.editYield'), color: 'success'})
          this.yieldAddModal = false
          this.getYieldPlans()
          this.getYieldTotalCrops()
        })
      }
    },
    setGrowPeriod(){
      const date1 = new Date(this.seedingDay);
      const date2 = new Date(this.yieldDay);
      const diffDate = date1.getTime() - date2.getTime();
      if(diffDate > 0){
        this.$store.dispatch(ACT_ADD_ALERT, {message: i18n.t('farm.yield.beforeSeeding')})
        return
      }
      const dateDays = Math.abs(diffDate / (1000 * 3600 * 24));
      this.yieldGrowPeriod = dateDays
    },
    setYieldDay(){
      if(this.yieldGrowPeriod < 0){
        this.$store.dispatch(ACT_ADD_ALERT, {message: i18n.t('farm.yield.beforeSeeding')})
        return
      }
      const seedingDay = new Date(this.seedingDay)
      const yieldDay = new Date(seedingDay.setDate(seedingDay.getDate() + Number(this.yieldGrowPeriod)))
      let year = yieldDay.getFullYear();
      let month = ('0' + (yieldDay.getMonth() + 1)).slice(-2);
      let day = ('0' + yieldDay.getDate()).slice(-2);

      let dateString = year + '-' + month  + '-' + day;
      this.yieldDay = dateString
    },
    setSeedingDay(){
      const yieldDay = new Date(this.yieldDay)
      const seedingDay = new Date(yieldDay.setDate(yieldDay.getDate()-Number(this.yieldGrowPeriod)))
      let year = seedingDay.getFullYear();
      let month = ('0' + (seedingDay.getMonth() + 1)).slice(-2);
      let day = ('0' + seedingDay.getDate()).slice(-2);
      let dateString = year + '-' + month  + '-' + day;

      return dateString
    },
    calTotalWeight(){
      this.yieldAmount = (this.yieldAvgWeight*this.yieldPotCount/1000).toFixed(1)
    },
    setAvgWeight(){
      this.yieldAvgWeight = (this.yieldAmount/this.yieldPotCount*1000).toFixed(1)
    },
    showYieldAddModal(item){
      if(item.fieldK != undefined){
        this.cropNo = item.cropNo
        this.farmYieldNo = 0
        this.farmYieldPlanNo = item.farmYieldPlanNo
        this.yieldAddModal = true
        this.yieldDay = item.fieldF
        this.yieldGrowPeriod = 0
        this.yieldPotCount = item.fieldI
        this.yieldAvgWeight = 0
        this.yieldAmount = 0
        this.yieldGrade = ''
        this.yieldIssue = ''
        this.seedingDay = item.fieldB
        this.lastfeedPotCount = item.fieldI
        this.setGrowPeriod()
      }else{
        this.cropNo = item.cropNo
        this.farmYieldNo = item.farmYieldNo
        this.farmYieldPlanNo = item.farmYieldPlanNo
        this.yieldAddModal = true
        this.yieldDay = item.fieldA
        this.yieldGrowPeriod = item.fieldB
        this.yieldPotCount = item.fieldC
        this.yieldAvgWeight = item.fieldD
        this.yieldAmount = item.fieldE
        this.yieldGrade = (item.fieldF == '-' ? '' : item.fieldF)
        this.yieldIssue = item.issue
        this.seedingDay = this.setSeedingDay()
        this.lastfeedPotCount = item.lastfeedPotCount
      }
    },
    showRackModal(){
      this.rackModal = true
      this.rackNm = ''
      this.rackConnect = ''
      this.rackRowCount = 0
      this.rackColumnCount = 0
      this.rackPotCount = 0
      this.rackDeleteLocation = ''
      this.rackDeleteOptions = []
    },
    updateRackModal(){
      let rackInfo = {
        fno: this.fno,
        growType: this.growType,
        rackLocation: this.rackNm,
        systemId: this.rackConnect,
        rackRowCount: this.rackRowCount,
        rackColumnCount: this.rackColumnCount,
        potCount: this.rackPotCount,
      }
      let state = true
      let rackExist = false

      Object.keys(rackInfo).forEach(key =>{
        if(rackInfo[key] == 0 || rackInfo[key] == '' || rackInfo[key] == null){
          state = false
        }
      })
      this.racks.forEach(rack =>{
        if(rackInfo.rackLocation == rack.rackLocation){
          rackExist = true
        }
      })

      if(state == true && rackExist == false){
        this.$store.dispatch(ACT_INSERT_MY_FARM_RACK, rackInfo).then(resp => {
          if(resp){
            this.getRack()
          }
        })
        this.$store.dispatch(ACT_ADD_ALERT, {message: i18n.t('farm.yield.addRackSuccess'), color: 'success'})
        this.rackModal = false
      }else if(state == true && rackExist == true){
        this.$store.dispatch(ACT_ADD_ALERT, {message: i18n.t('farm.yield.rackLocationDuplicated'), color: 'danger'})
      }else{
        this.$store.dispatch(ACT_ADD_ALERT, {message: i18n.t('farm.yield.enterAllValue'), color: 'danger'})
      }
    },
    showRackDeleteModal(){
      this.rackDeleteLocation = ''
      this.rackDeleteOptions = []
      this.racks.forEach(rack =>{
        this.rackDeleteOptions.push(rack.rackLocation)
      })
      this.rackDeleteModal = true;
    },
    updateRackDeleteModal(){
      let farmRackNo = 0
      this.racks.forEach(rack =>{
        if(rack.rackLocation == this.rackDeleteLocation){
          farmRackNo = rack.farmRackNo
        }
      })
      if(farmRackNo != 0){
        this.$store.dispatch(ACT_DELETE_MY_FARM_RACK, farmRackNo).then(resp =>{
          if(isSuccess(resp)){
          this.getRack()
          this.$store.dispatch(ACT_ADD_ALERT, {message: i18n.t('farm.yield.deleteRackSuccess'), color: 'success'})
          this.rackDeleteModal = false;
          }else{
            this.$store.dispatch(ACT_ADD_ALERT, {message: i18n.t('farm.yield.usedRack'), color: 'danger'})
          }
        })
      }else{
        this.$store.dispatch(ACT_ADD_ALERT, {message: i18n.t('farm.yield.rightRack'), color: 'danger'})
      }
    },
    showYieldPlanModal(item){
      this.yieldPlanModal = true;
      this.rackLocationValidation = true
      if(item!=null){
        this.farmYieldPlanNo = item.farmYieldPlanNo
        this.cropNo = item.cropNo
        this.rackLocation = item.fieldH
        this.systemId = item.systemId
        this.seedingDay = item.fieldB
        this.seedlingDay = item.fieldC
        this.transplantDay = item.fieldD
        this.lastfeedDay = item.fieldE
        this.yieldPlanDay = item.fieldF
        this.lastfeedPotCount = item.fieldI
        this.yieldPlanAmount = item.fieldJ
        this.cropNm = item.fieldA
        this.setDays()
      }else{
        this.farmYieldPlanNo = 0
        this.cropNo = 0
        this.rackLocation = ''
        this.systemId = 0
        this.seedingDay = ''
        this.seedlingDay = ''
        this.transplantDay = ''
        this.lastfeedDay = ''
        this.yieldPlanDay = ''
        this.lastfeedPotCount = 0,
        this.yieldPlanAmount = 0
      }
    },
    setYieldPlanAmount(){
      let standardW = 0
      this.cropEnvList.forEach(cropEnv =>{
        if(cropEnv.value == this.cropNo){
          standardW = cropEnv.text.standardWeight
        }
      })
      this.yieldPlanAmount = this.lastfeedPotCount*standardW/1000
    },
    checkConnect(){
      if(this.rackLocationValidation){
        this.$store.dispatch(ACT_ADD_ALERT, {message: i18n.t('farm.yield.selectController'), color: 'danger'})
      }
    },
    updateYieldPlanModal(){
      let params = {
        cropNo : this.cropNo,
        rackLocation : this.rackLocation,
        systemId : this.systemId,
        seedingDay : this.seedingDay,
        seedlingDay : this.seedlingDay,
        transplantDay : this.transplantDay,
        lastfeedDay : this.lastfeedDay,
        yieldPlanDay : this.yieldPlanDay,
        lastfeedPotCount : this.lastfeedPotCount,
        yieldPlanAmount : this.yieldPlanAmount
        }
      this.nullCheck = false
      Object.keys(params).forEach((key)=>{
          if(params[key]==0 || params[key]=='' || params[key]==null){
            this.nullCheck = true
          }
      })
      if(!this.nullCheck){
        if(this.farmYieldPlanNo == 0){
          this.$store.dispatch(ACT_INSERT_MY_FARM_YIELD_PLAN, params).then((resp) =>{
            if(isSuccess(resp)){
              this.$store.dispatch(ACT_ADD_ALERT, {message: i18n.t('farm.yield.addYieldPlan'), color: 'success'});
              this.getYieldPlans()
            }else{
              this.$store.dispatch(ACT_ADD_ALERT, {message: getResult(resp).message});
            }
          })
          this.yieldPlanModal = false
        }else{
          const yesCallback = () =>{
            this.$store.dispatch(ACT_UPDATE_MY_FARM_YIELD_PLAN, {yno :this.farmYieldPlanNo, params: params}).then((resp) =>{
              if(isSuccess(resp)){
                this.$store.dispatch(ACT_ADD_ALERT, {message: this.i18n.cropD1 + this.cropNm + this.i18n.cropD3, color: 'success'});
                this.getYieldPlans()
              }else{
                this.$store.dispatch(ACT_ADD_ALERT, {message: getResult(resp).message});
              }
            })
          }
          this.$store.dispatch(ACT_SHOW_CONFIRM_ALERT, {
            info: {title: this.i18n.cropD1 + this.cropNm + this.i18n.cropD2},
            callback: yesCallback
          })
          this.yieldPlanModal = false
        }
      }else{
        let info = {}
        info.title = this.i18n.rightValue
        info.icon = 'error'
        info.focusConfirm = true
        info.confirmButtonColor = '#131628'
        info.confirmButtonText = this.i18n.confirm
        Vue.swal.fire(info)
      }
    },
    delYieldPlanModal(item){
      const yesCallback = () =>{
        this.$store.dispatch(ACT_DELETE_MY_FARM_YIELD_PLAN, item.farmYieldPlanNo).then((resp) =>{
            if(isSuccess(resp)){
              this.$store.dispatch(ACT_ADD_ALERT, {message: i18n.t('farm.yield.successDelete'), color: 'success'}).then(()=>{
                this.getYieldTotalCrops()
                this.getYieldPlans()
              })
            }else{
              this.$store.dispatch(ACT_ADD_ALERT, {message: getResult(resp).message});
            }
          })
      }

      this.$store.dispatch(ACT_SHOW_CONFIRM_ALERT, {
        info: {title: i18n.t('farm.yield.deleteMessage')},
        callback: yesCallback
      })
    },
    getYieldPlans(){
      if(this.selectCropNo != 1){
        const params = {
            cropNo: this.selectCropNo,
            startYmd: this.startYmd,
            endYmd: this.endYmd,
            pageNo: this.paging.pageNo,
            pageSize: this.paging.pageSize,
            totalCount: this.paging.totalCount
        }
        this.crops = []
        this.cropsExpand = {}
        this.$store.dispatch(ACT_GET_MY_FARM_YIELD_PLAN, params).then(resp =>{
          resp.items.forEach((item)=>{
            this.$store.dispatch(ACT_GET_MY_FARM_YIELD, item.farmYieldPlanNo).then(res=>{
              this.crops.push(item)
              this.cropsExpand[item.farmYieldPlanNo] = res.items
              this.cropsExpand[item.farmYieldPlanNo].forEach((x,idx)=>{
                this.cropsExpand[item.farmYieldPlanNo][idx].lastfeedPotCount = item.lastfeedPotCount
                this.cropsExpand[item.farmYieldPlanNo][idx].farmYieldPlanNo = item.farmYieldPlanNo
                this.cropsExpand[item.farmYieldPlanNo][idx].cropNo = item.cropNo
              })
            }).finally(()=>{
              if(this.crops.length == resp.items.length){
                this.crops.sort((a, b) => {
                  return a.lastfeedDay > b.lastfeedDay ? -1 : (a.lastfeedDay < b.lastfeedDay ? 1 : 0)
                })
              }
            })
          })
        this.paging.totalCount = resp.paging.totalCount
      })
      }else{
        const params = {
            startYmd: this.startYmd,
            endYmd: this.endYmd,
            pageNo: this.paging.pageNo,
            pageSize: this.paging.pageSize,
            totalCount: this.paging.totalCount
        }
        this.crops = []
        this.cropsExpand = {}
        this.$store.dispatch(ACT_GET_MY_FARM_YIELD_PLAN, params).then(resp=>{
          resp.items.forEach((item)=>{
            this.$store.dispatch(ACT_GET_MY_FARM_YIELD, item.farmYieldPlanNo).then(res=>{
              this.crops.push(item)
              this.cropsExpand[item.farmYieldPlanNo] = res.items
              this.cropsExpand[item.farmYieldPlanNo].forEach((x,idx)=>{
                this.cropsExpand[item.farmYieldPlanNo][idx].lastfeedPotCount = item.lastfeedPotCount
                this.cropsExpand[item.farmYieldPlanNo][idx].farmYieldPlanNo = item.farmYieldPlanNo
                this.cropsExpand[item.farmYieldPlanNo][idx].cropNo = item.cropNo
              })
            }).finally(()=>{
              if(this.crops.length == resp.items.length){
                this.crops.sort((a, b) => {
                  return a.lastfeedDay > b.lastfeedDay ? -1 : (a.lastfeedDay < b.lastfeedDay ? 1 : 0)
                })
              }
            })
          })
        this.paging.totalCount = resp.paging.totalCount
        })
      }
    },
    setDays(){
      this.cropEnvList.forEach((item)=>{
        if(item.value == this.cropNo){
          this.cropEnv = {
            seedMedianDay: Number(item.text.seedMedianDay),
            transplantMedianDay: Number(item.text.transplantMedianDay),
            lastfeedMedianDay: Number(item.text.lastfeedMedianDay),
          }
        }
      })
    },
    getRack(){
      this.$store.dispatch(ACT_GET_MY_FARM_RACK, this.fno).then(resp => {
        this.racks = []
        resp.items.forEach(item => {
         this.racks.push(item)
        })
        this.setRack()
      })
    },
    setRack(){
      this.rackOptions = []
      this.racks.forEach(rack =>{
        if(rack.systemId == this.systemId){
          this.rackOptions.push(rack.rackLocation)
        }
      })
    },
    checkRackLocation(){
      this.rackLocationValidation = false
      let check = true
      this.rackOptions.forEach(rackOption => {
        if(rackOption == this.rackLocation){
          check = false
        }
      })
      if(check && this.rackLocation != ''){
        this.rackLocation = ''
        this.$store.dispatch(ACT_SHOW_ERROR_ALERT, i18n.t('farm.yield.rightRack'))
      }
    },
    //파종일 변경시 육묘,이식,정식,수확일 자동계산
    seedingDayChanged(){
      this.seedlingDay = dateAddDel(this.seedingDay,2,'d')
      this.transplantDay = dateAddDel(this.seedlingDay,this.cropEnv.seedMedianDay,'d')
      this.lastfeedDay = dateAddDel(this.transplantDay,this.cropEnv.transplantMedianDay,'d')
      this.yieldPlanDay = dateAddDel(this.lastfeedDay,this.cropEnv.lastfeedMedianDay,'d')
      // let sdt = new Date(this.seedingDay);
      // let ydt = new Date(this.yieldPlanDay);
      // let dateDiff = Math.ceil((ydt.getTime()-sdt.getTime())/(1000*3600*24));
      // this.growPeriod = dateDiff
    },
    getYMD(){
      let now = new Date()
      let today = new Date(now.setDate(now.getDate()+1))
      let year = today.getFullYear() // 년도
      let month = today.getMonth() + 1; month = (month < 10) ? '0' + month : month;  // 월
      let date = today.getDate()  // 날짜

      return year + '-' + month + '-' + date
    },
    linkGen(pageNum) {
      return pageNum === 1 ? '?' : `?page=${pageNum}`
    },
    yieldlinkGen(pageNum) {
      return pageNum === 1 ? '?' : `?yieldPage=${pageNum}`
    },
    getCountIdx(idx) {
      return this.paging.totalCount - ((this.paging.pageNo - 1) * this.paging.pageSize) - idx;
    },
    yieldGetCountIdx(idx) {
      return this.yieldPaging.totalCount - ((this.yieldPaging.pageNo - 1) * this.yieldPaging.pageSize) - idx;
    },
    changeConnect(i){
      this.tableState = i
    },
  }
};

</script>


<style>
  tr.v-data-table__expanded {
    background: #E4E6EF !important;
  }
</style>